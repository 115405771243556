<template>
  <div class="maintenance-wrapper">
    <img src="https://resource.pafcpic.net/assets/pia/images/elements/papsi_logo.png" alt="PAFCPIC Logo" class="logo">
    <div class="maintenance-container">
      <img src="https://resource.pafcpic.net/assets/pia/images/elements/papsi_kalabaw.png" alt="Maintenance Icon" class="icon" />
      <h1>We'll be back soon!</h1>
      <p>Our system is currently under maintenance. We apologize for any inconvenience and appreciate your patience.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  background: radial-gradient(circle, rgba(229, 231, 235, 1) 10%, rgb(149, 155, 167) 100%);
  text-align: center;
  color: #2c3e50;
  display: flex;
  justify-content: center;
  align-items: center;
}

.maintenance-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 13rem;
  position: absolute;
  top: 10%;
}

.maintenance-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.icon {
  width: 15rem;
  max-width: 80%;
}

/* Headings */
h1 {
  font-size: 2.5rem;
  color: #016B00;
  margin: 1rem 0;
}

p {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  padding: 0 1rem;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .icon {
    width: 12rem;
  }
  h1 {
    font-size: 2rem;
  }
  p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .logo {
    width: 10rem;
  }
  .icon {
    width: 10rem;
  }
  h1 {
    font-size: 1.8rem;
  }
  p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .logo {
    width: 8rem;
  }
  .icon {
    width: 8rem;
  }
  h1 {
    font-size: 1.5rem;
  }
  p {
    font-size: 0.8rem;
  }
}
</style>
